import { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";

function BasicExample() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <ToastContainer className="position-fixed mb-5 mr-4" position="bottom-end">
      <Toast onClose={() => setShow(false)} show={show} delay={8000} autohide>
        <Toast.Header>
          <strong className="me-auto">Welcome visitor!</strong>
        </Toast.Header>
        <Toast.Body>
          This website is made from scratch by me, feel free too drop in your
          suggestions ❤️
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default BasicExample;

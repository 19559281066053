import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import logo1 from "../content/images/logo1.png";

function Navigation() {
  return (
    <>
      <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand>
            <img
              src={logo1}
              width="50"
              height="40"
              className="d-inline-block align-middle"
              alt=""
            />
            Divik Shrivastava
          </Navbar.Brand>
          <Nav className="me-auto">
            <Link activeClass="active" smooth to="home">
              <Nav.Link>Home</Nav.Link>
            </Link>
            <Link activeClass="active" smooth to="skills">
              <Nav.Link>Skills</Nav.Link>
            </Link>
            <Link activeClass="active" smooth to="projects">
              <Nav.Link>Projects</Nav.Link>
            </Link>
            <Link activeClass="active" smooth to="experiences">
              <Nav.Link>Experiences</Nav.Link>
            </Link>
            <Link activeClass="active" smooth to="certifications">
              <Nav.Link>Certifications</Nav.Link>
            </Link>
            <Link activeClass="active" smooth to="contact">
              <Nav.Link>Contact Me</Nav.Link>
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;

// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import helen from "../content/images/helen.gif";
import covid from "../content/images/covid.png";
import roshni from "../content/images/roshni.gif";
import foodie from "../content/images/foodie.png";
import chemericka from "../content/images/chemericka.gif";
import imdb from "../content/images/imdb.gif";

function Projects() {
  return (
    <div className="flex justify-center text-white py-20" id="projects">
      <div className="flex flex-col">
        <div className="flex justify-center text-6xl pb-5 z-50">Undergraduate Projects</div>
        <div className="grid grid-cols-3 gap-3">
          <div className="flex flex-col justify-center space-y-4">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={helen} />
              <Card.Body>
                <Card.Title>H.E.L.E.N</Card.Title>
                <Card.Text>
                  Using the prediction results and a decision tree, a custom
                  approach was designed to accurately predict and interpret the
                  gestures based on ASL (American Sign Language), then announced
                  using the GTTS API.
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="flex flex-col space-y-3">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={covid} />
              <Card.Body>
                <Card.Title>CoviBot</Card.Title>
                <Card.Text>
                  Developed a python program to detect if a CRT scan belongs to
                  a COVID positive patient or other diseases, by applying
                  feature extraction and CNN.
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={roshni} />
              <Card.Body>
                <Card.Title>R.O.S.H.N.I</Card.Title>
                <Card.Text>
                  Using Tensorflow and Keras libraries of Python, developed an
                  Image Processing and Classiﬁcation-based project to identify
                  the denomination and authenticity of currency (Indian Rupee)
                  for the blind through CNN trained on a dataset of 2000+ images
                  and techniques like Binarization, Canny edge detection and
                  Contouring.
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={foodie} />
              <Card.Body>
                <Card.Title>Dr. Foodie</Card.Title>
                <Card.Text>
                  An object detection project, leveraging OpenCV for user input
                  through webcam and Deep Learning models like Single Shot
                  MultiBox Detector(SSD) and You Only Look Once (YOLO), where
                  food items in a picture of a meal are recognized and their
                  calorie count is displayed on a standard food chart dataset.
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={imdb} />
              <Card.Body>
                <Card.Title>Internet Movie Database</Card.Title>
                <Card.Text>
                This is a web movie database aiming to incorporate weighted parameters for more accurate movie recommendations
It has custom CMS for content creation, user management, and dynamic page rendering, providing a richer and personalized experience
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Img variant="top" src={chemericka} />
              <Card.Body>
                <Card.Title>Chemericka</Card.Title>
                <Card.Text>
                A tailored software solution employing JDBC along with regex based pattern matching, allowing users to seamlessly determine reactant product outcomes
                </Card.Text>
                {/* <Button variant="primary">Check this out</Button> */}
              </Card.Body>
            </Card>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Projects;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPython,
  faNodeJs,
  faReact,
  faAws,
  faJenkins,
  faJira,
  faDocker,
} from "@fortawesome/free-brands-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function Skills() {
  return (
    <div className="flex justify-center bg-dark text-white py-20" id="skills">
      <div className="flex flex-col">
        <div className="flex justify-center text-6xl pb-4 z-50">Skills</div>
        <div className="flex space-x-40 z-50">
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faPython} size="3x" />
            <ProgressBar now={70} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id={`tooltip-top`}>Node js</Tooltip>}
            >
              <FontAwesomeIcon icon={faNodeJs} size="3x" />
            </OverlayTrigger>
            <ProgressBar now={80} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faReact} size="3x" />
            <ProgressBar now={80} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faJira} size="3x" />
            <ProgressBar now={90} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faJenkins} size="3x" />
            <ProgressBar now={75} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faDocker} size="3x" />
            <ProgressBar now={60} />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <FontAwesomeIcon icon={faAws} size="3x" />
            <ProgressBar now={30} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;

import React from "react";
import Navigation from "./components/NavigationBar";
// import CarouselFade from "./MainCaraousal";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Certifications from "./components/Certifications";
import ContactForm from "./components/ContactForm";
import ParticlesBackground from "./components/ParticlesBackground";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BasicExample from "./components/Toast";

function App() {
  return (
    <div className="theme-default">
      <header>
        <Navigation />
        {/* <Router>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/experiences" element={<Experience />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </Router> */}
      </header>
      <body className="theme-default">
        <ParticlesBackground />
        <Home />
        <Skills />
        <Projects />
        <Experience />
        <Certifications />
        <ContactForm />
        <BasicExample />
      </body>
    </div>
  );
}

export default App;

import Card from "react-bootstrap/Card";
import profile from "../content/images/profilePic.webp";
import resume from "../content/resume/Resume_Divik.pdf";
import SocialHandles from "./SocialHandles";
import "../App.css";
import Button from "react-bootstrap/Button";
import { FcDocument } from "react-icons/fc";

function Home() {
  return (
    <div className="flex flex-wrap justify-center mt-16" id="home">
      <div className="flex flex-col z-10">
        <img
          src={profile}
          width="450"
          height="600"
          className="d-inline-block align-middle"
          alt=""
        />
        <Card
          style={{ width: "28rem" }}
          text="white"
          className="theme-default "
          border="secondary"
        >
          <Card.Body>
            <Card.Title className="flex justify-center">
              DIVIK SHRIVASTAVA
            </Card.Title>
            <Card.Subtitle className="flex justify-center mb-2 text-muted">
              Software Developer | Grad student at SJSU
            </Card.Subtitle>
            <SocialHandles />
          </Card.Body>
        </Card>
      </div>
      <div className="flex flex-col">
        <Card style={{ width: "50rem" }} className="theme-default" text="white">
          <Card.Body>
            <Card.Title>
              <h1>The Pitch</h1>
            </Card.Title>
            <Card.Text>
              <h5>
                Before reading a generic bio filled with in demand skills and
                keywords (to boost the SEO), I am just a simple software
                developer with an innate love of solving complex problems with
                the help of code, always looking out for opportunities to
                showcase my prowess as a programmer.
                <br />
                <br />
                Having over 3 years of experience in Full Stack Development in a
                startup environment, particularly in backend, I have learned how
                to design, develop, restore, maintain, test and evaluate various
                software projects. Experienced in understanding and drafting
                code structure and architectures, and adopting an innovative and
                solution-oriented approach to cater for the organizational
                requirements. Skilled in building and maintaining performance
                optimized features, with good exposure to security standards.
                Highly skilled in communication, collaboration, and technical
                documentation.
              </h5>
              <h4>
                Currently seeking software engineering internships for Summer 2024.
              </h4>
            </Card.Text>
            <a
              href={resume}
              target="_blank"
              rel="noopener noreferrer"
              download="Resume_Divik_Shrivastava1.pdf"
            >
              <Button>
                <div className="flex flex-wrap content-start">
                  <FcDocument style={{ fontSize: "25px" }} /> Download my resume
                </div>
              </Button>
            </a>
          </Card.Body>
        </Card>
        <div className="flex flex-wrap">
          <Card
            body
            style={{ width: "25rem" }}
            className="theme-default"
            text="white"
          >
            Interests
            <ul className="list-disc">
              <li>Full Stack Development</li>
              <li>Microservices</li>
              <li>Distributed System</li>
              <li>Cloud</li>
              <li>SaaS</li>
              <li>MERN/MEAN stack</li>
              <li>AGILE</li>
              <li>Database management</li>
              <li>Serverless Design</li>
              <li>API First</li>
            </ul>
          </Card>
          <Card
            body
            style={{ width: "25rem" }}
            className="theme-default"
            text="white"
          >
            Education
            <ul className="list-disc">
             <li>
                San Jose State University <br />
                (Master of Science, Software Engineering, 2023-25)
              </li>
              <li>
                Jaypee Institute of Information Technology, Noida <br />
                (Bachelor of Technology, Computer Science, 2016-20)
              </li>
              <li>
                Delhi Public School, Bhopal <br />
                (12th Grade)
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Home;

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import safe from "../content/images/safe.png";
import tcs from "../content/images/tcs.png";
import bert from "../content/images/bert.png";
import infotek from "../content/images/infotek.png";

function Experience() {
  return (
    <div
      className="flex justify-center bg-dark text-white py-20"
      id="experiences"
    >
      <div className="flex flex-col">
        <div className="flex justify-center text-6xl pb-5 z-50">
          Experiences
        </div>
        <div className="flex space-x-40">
          <div className="flex flex-col justify-center space-y-4">
          <div className="flex flex-row">
              <Card style={{ width: "70rem" }} bg="secondary" text="white">
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-row">
                      <img src={bert} width="35" height="35" alt="" />
                      <div className="self-center pl-2">
                        Senior Research Engineer (January 2023 - June 2023)
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">Bert Labs</Card.Subtitle>
                  <Card.Text>
                    ● &nbsp;&nbsp;Designed real-time dashboards via backend REST API on Socket.io and Express to visualize operational data from IoT devices.
                    <br />
                    ● &nbsp;&nbsp;Conceptualized and developed cutting-edge Digital Twin dashboards, driven by first-principle modeling, for diverse Industry 4.0 clientele, achieving enhanced operational insights and client satisfaction
                    <br />● &nbsp;&nbsp;Integrated reinforcement learning models with a dashboard via Flask & achieved 6% savings
                    <br />● &nbsp;&nbsp;Lead a high-performing team of six developers and designers, delivering 2 epics
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="flex justify-center d-flex h-16 ml-4">
              <div className="vr"></div>
            </div>
            <div className="flex flex-row">
              <Card style={{ width: "70rem" }} bg="secondary" text="white">
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-row">
                      <img src={safe} width="35" height="35" alt="" />
                      <div className="self-center pl-2">
                        Software Developer (August 2020 - December 2022)
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">Safe Security</Card.Subtitle>
                  <Card.Text>
                    ● &nbsp;&nbsp;Transformed the MITRE module with Redis caching, reducing load times for feature-rich components by 2-3 seconds
.
                    <br />
                    ● &nbsp;&nbsp;Efficiently led migration of legacy PHP code to TypeScript and Python, encompassing old pages, faulty APIs, and redundant database migrations, resulting in a 12% reduction in overall technical debt.
                    <br />● &nbsp;&nbsp;Enhanced existing product capabilities to bulk onboard 100k third parties via a single button click, using RabbitMQ
                    <br />● &nbsp;&nbsp;Implemented a seamless integration with JIRA Software to facilitate creation of tickets, dropping the creation time by 5 minutes
                    <br />● &nbsp;&nbsp;Spearheaded the integration of NIST Framework assessment capabilities for external domains, enabling the creation of user-friendly, data-rich dashboards for comprehensive third-party analysis and data visualization

                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="flex justify-center d-flex h-16 ml-4">
              <div className="vr"></div>
            </div>
            <div className="flex flex-row">
              <Card style={{ width: "70rem" }} bg="secondary" text="white">
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-row">
                      <img src={safe} width="35" height="35" alt="" />
                      <div className="self-center pl-2">Intern (Feb 2020 - July 2020)</div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">Safe Security</Card.Subtitle>
                  <Card.Text>
                    ● &nbsp;&nbsp;Developed Intelligent Sheet Scanner, where I
                    performed validations on huge datasets, and extracted
                    meaningful information from the CIS sheets using NLP
                    techniques.
                    <br />
                    ● &nbsp;&nbsp;Worked on NIST framework assessment for
                    clients, where I made score predictions using Bayesian
                    Network.
                    <br />● &nbsp;&nbsp;Worked on Node js, React js, Typescript
                    based projects and features as well as cloud infrastructures
                    like AWS and Azure.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="flex justify-center d-flex h-16 ml-4">
              <div className="vr"></div>
            </div>
            <div className="flex flex-row">
              <Card style={{ width: "70rem" }} bg="secondary" text="white">
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-row">
                      <img src={tcs} width="35" height="35" alt="" />
                      <div className="self-center pl-2">
                        Data Science Intern (June 2019 - August 2019)
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">
                    Tata Consultancy Services
                  </Card.Subtitle>
                  <Card.Text>
                    ● &nbsp;&nbsp;Performed sentiment analysis on a dataset
                    consisting of various employee comments associated with the
                    appraisal process using Python and Machine Learning.
                    <br />● &nbsp;&nbsp; Worked on concepts of NLP to clean and
                    process various datasets, tested and implemented LSTM and
                    various corpora for accurate result prediction.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="flex justify-center d-flex h-16 ml-4">
              <div className="vr"></div>
            </div>
            <div className="flex flex-row">
              <Card style={{ width: "70rem" }} bg="secondary" text="white">
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-row">
                      <img src={infotek} width="35" height="35" alt="" />
                      <div className="self-center pl-2">Intern (June 2018 - July 2018)</div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">
                    Infotek Solutions
                  </Card.Subtitle>
                  <Card.Text>
                    Studied various Machine Learning tools on AWS cloud like AWS
                    SageMaker and AWS Macie, through practical application.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../App.css";
import { AiOutlineMail } from "react-icons/ai";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import emailkey from "./emailkey";

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        emailkey.SERVICE_ID,
        emailkey.TEMPLATE_ID,
        form.current,
        emailkey.PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log("err", error.text);
        }
      );
  };
  return (
    <div className="flex justify-center bg-dark text-white py-20" id="contact">
      <div className="flex flex-col">
        <div className="flex justify-center text-6xl pb-5 z-50">Contact</div>
        <div className="flex flex-col z-50">
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3 contactFormWidth">
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                as="textarea"
                name="message"
                rows={3}
                placeholder="Message"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="contactFormWidth"
            >
              Send
            </Button>
          </Form>
          <div className="flex justify-center space-x-4">
            <AiOutlineMail style={{ fontSize: "25px" }} />
            <div className="align-middle">
              <h5>divikshrivastava@gmail.com</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

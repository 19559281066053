import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import react from "../content/images/react.png";
import aws from "../content/images/aws.png";
import gc_bml from "../content/images/gc_bml.png";
import gc_dma from "../content/images/gc_dma.png";
import gc_lp from "../content/images/gc_lp.png";
import gc_ml from "../content/images/gc_ml.png";
import gc_tf from "../content/images/gc_tf.png";

function Certifications() {
  return (
    <div className="flex justify-center text-white py-20" id="certifications">
      <div className="flex flex-col">
        <div className="flex justify-center text-6xl pb-5 z-50">
          Selected Certifications and Accomplishments
        </div>
        <div className="flex space-x-60">
          <div className="grid grid-cols-4 gap-4 place-items-start">
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Certification</Card.Header>
              <Card.Img variant="top" src={react} />
              <Card.Body>
                <a href="https://www.udemy.com/certificate/UC-19f2fb48-ad7f-45d0-80af-cf5aac6bcbfd/">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={aws} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/164283">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={gc_bml} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/161889">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={gc_dma} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/161483">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={gc_lp} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/161720">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={gc_ml} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/162703">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }} bg="dark" text="white">
              <Card.Header as="h4">Online course</Card.Header>
              <Card.Img variant="top" src={gc_tf} />
              <Card.Body>
                <a href="https://www.cloudskillsboost.google/public_profiles/63d29913-abd3-4b5d-b8b5-214dc0a50b06/badges/162264">
                  <Button variant="primary">credential link</Button>
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certifications;
